























































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { NewAccount } from "@/types";
import { ValidMimeTypes } from "@/assets/classes/ValidMimeTypes";
import RequestHandler from "@/assets/ts/requestHandler";
import DocumentationHandler from "@/assets/ts/documentationHandler";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import smartyStreets from "@/components/smarty-streets/smarty-streets.vue";

import vueDebounce from "vue-debounce";
Vue.use(vueDebounce);

const RH = new RequestHandler();
const DH = new DocumentationHandler();

@Component({
  components: {
    VuePhoneNumberInput,
    smartyStreets
  }
})
export default class Signup extends Vue {
  private phoneNumberInput = "";

  private currentStep = 1;

  private useEnteredAddress = false;
  private configLoaded = false;
  private config = {};

  private newAccount: NewAccount = {
    primaryUser: {
      id: 0,
      username: "",
      email: "",
      fullname: "",
      password: ""
    },
    mvdsCustomerType: {
      mvdsCustomerTypeID: 0
    },
    vtrsCustomer: {
      customerType: "B",
      customerName: "",
      customerEmail: "",
      contactPhone: "",
      businessCustomer: { businessName: "" },
      physicalAddress: {
        streetNo: "",
        address1: "",
        city: "",
        state: "",
        postalCode: "",
        zip5: "",
        countyID: -1
      }
    },
    mvdsCustomerID: 0,
    accountName: "",
    accountNo: 0,
    routingNo: "",
    statusCode: "P",
    digitalSignature: ""
  };

  private userInfo = {
    showPass: false,
    firstName: "",
    lastName: "",
    stateBoxItems: [],
    businessTypeBoxItems: [] as {}[],
    businessType: -1,
    valid: true
  };

  private address = {
    address1: "",
    address2: "",
    zipCode: "",
    valid: true
  };

  private bankInfo = {
    bankName: "",
    accountNo: "",
    confirmAccountNo: "",
    valid: true,
    useCreditCard: false,
    routingNoErrorMsg: "",
    accountNoErrorMsg: "",
    confirmAccountNoErrorMsg: "",
    accountNameErrorMsg: ""
  };

  private documentation = {
    businessLicense: [] as any,
    statement: [] as any,
    valid: true,
    errorMessage: "",
    showError: false,
    validMimeTypes: [
      "image/jpeg",
      "image/png",
      "image/tiff",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ]
  };

  private dppaForm = {
    text: "unset",
    showTermsError: false,
    showServerError: false,
    errorMessage: "",
    showError: false,
    valid: true,
    scrolled: false,
    accepted: false
  };

  private termsAndConditions = {
    text: "unset",
    accepted: false,
    showTermsError: false,
    showServerError: false,
    errorMessage: "",
    showError: false,
    creationLoading: false,
    scrolled: false
  };

  private smartyStreetsVuetifyProps = {
    outlined: true,
    dense: true
  };

  private emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private rules = {
    required: (value: string | number) => !!value || "This field is required.",
    username: (value: string) => {
      const pattern = /^[a-zA-Z0-9_\-.!@]{4,50}$/;
      return (
        pattern.test(value) ||
        "Must be 4 to 50 characters long and can contain letters, numbers, and certain special characters"
      );
    },
    password: (value: string) => {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_+={[}\]|\\:;"'<,>.?/-])[A-Za-z\d~`!@#$%^&*()_+={[}\]|\\:;"'<,>.?/-]{8,}$/;
      return (
        pattern.test(value) ||
        "Must contain one lowercase letter, one uppercase letter, one number, one special character, and be at least eight characters in total"
      );
    },
    email: (value: string) => {
      return this.emailRegex.test(value) || "Invalid e-mail address.";
    },
    zipcode: (value: string) => {
      const pattern = /^\d{5}(-\d{4})?$/;
      return pattern.test(value) || "Invalid zip code.";
    },
    phone: (value: string) => {
      const pattern = /^\d{10}$/;
      return pattern.test(value) || "Invalid phone number.";
    },
    dropdown: (value: number) =>
      (!!value && value != -1) || "This field is required."
  };

  get useEmailAsUserName() {
    if (this.emailRegex.test(this.newAccount.primaryUser.username)) {
      this.newAccount.primaryUser.email = this.newAccount.primaryUser.username;
      return true;
    }
    return false;
  }

  private validateAddress() {
    if (
      document.querySelector(".error--text input") === undefined ||
      document.querySelector(".error--text input") === null
    ) {
      this.currentStep++;
    }
  }

  private async validateUserInfo() {
    let usernameExists = true;
    let emailExists = true;
    let businessNameExists = true;
    if (this.userInfoForm.validate()) {
      try {
        usernameExists = await RH.checkForExistence(
          "username",
          this.newAccount.primaryUser.username
        );
        emailExists = await RH.checkForExistence(
          "email",
          this.newAccount.primaryUser.email
        );
        businessNameExists = await RH.checkForExistence(
          "businessName",
          this.newAccount.vtrsCustomer.businessCustomer.businessName
        );

        if (!usernameExists && !emailExists && !businessNameExists) {
          this.currentStep++;
        } else {
          let messageText = "";
          if (usernameExists) {
            messageText += "Username is taken. ";
          }
          if (emailExists) {
            messageText += "Email is taken. ";
          }
          if (businessNameExists) {
            messageText += "Business name is taken. ";
          }
          messageText += "Please try again.";
          this.$root.$emit("snackbar-message", messageText);
        }
      } catch (e) {
        this.$root.$emit(
          "snackbar-message",
          "Error checking to determine if chosen username and email are unique."
        );
      }
    }
  }

  private async validateBankInfo() {
    if (
      [null, ""].includes(this.bankInfo.accountNo) &&
      [null, ""].includes(this.newAccount.routingNo) &&
      [null, ""].includes(this.newAccount.accountName) &&
      [null, ""].includes(this.bankInfo.confirmAccountNo) &&
      this.bankInfo.useCreditCard
    ) {
      this.clearBankFields();
      this.bankInfo.valid = true;
    } else if (
      ![null, ""].includes(this.bankInfo.accountNo) &&
      ![null, ""].includes(this.newAccount.routingNo) &&
      ![null, ""].includes(this.newAccount.accountName) &&
      ![null, ""].includes(this.bankInfo.confirmAccountNo) &&
      this.bankInfo.confirmAccountNo === this.bankInfo.accountNo
    ) {
      this.validateRoutingNo();
      this.validateAccountNo();
      if (
        this.bankInfo.routingNoErrorMsg === "" &&
        this.bankInfo.accountNoErrorMsg === ""
      ) {
        Object.assign(this.bankInfo, {
          accountNameErrorMsg: "",
          confirmAccountNoErrorMsg: "",
          valid: true
        });
      } else {
        this.bankInfo.valid = false;
      }
    } else {
      Object.assign(this.bankInfo, {
        accountNoErrorMsg: "",
        routingNoErrorMsg: "",
        accountNameErrorMsg: "",
        confirmAccountNoErrorMsg: ""
      });
      if ([null, ""].includes(this.bankInfo.accountNo)) {
        this.bankInfo.accountNoErrorMsg = "Please enter an account number.";
      } else {
        this.validateAccountNo();
      }
      if ([null, ""].includes(this.newAccount.routingNo)) {
        Object.assign(this.bankInfo, {
          bankName: "",
          routingNoErrorMsg: "Please enter a routing number."
        });
      } else {
        this.validateRoutingNo();
      }
      if ([null, ""].includes(this.newAccount.accountName)) {
        this.bankInfo.accountNameErrorMsg = "Please enter an account name.";
      }
      if ([null, ""].includes(this.bankInfo.confirmAccountNo)) {
        this.bankInfo.confirmAccountNoErrorMsg =
          "Please confirm the account number";
      } else if (this.bankInfo.confirmAccountNo !== this.bankInfo.accountNo) {
        this.bankInfo.confirmAccountNoErrorMsg =
          "This value must be the same as the account number.";
      }
      this.bankInfo.valid = false;
    }
  }

  private async validateRoutingNo() {
    try {
      const response = await RH.validateRoutingNo(this.newAccount.routingNo);
      this.bankInfo.bankName = response.bankName;
      this.bankInfo.routingNoErrorMsg = "";
    } catch (e) {
      this.bankInfo.bankName = "";
      this.bankInfo.routingNoErrorMsg = "Invalid routing number.";
    }
  }

  private validateAccountNo() {
    const pattern = /^[a-zA-Z0-9]+$/;
    if (pattern.test(this.bankInfo.accountNo)) {
      this.bankInfo.accountNoErrorMsg = "";
    } else {
      this.bankInfo.accountNoErrorMsg =
        "Account numbers may contain only letters and numbers.";
    }
  }

  private checkBankValidation() {
    this.validateBankInfo();
    if (this.bankInfo.valid) {
      this.currentStep++;
    }
  }

  private validateDocumentation() {
    this.documentation.valid = false;
    this.documentation.showError = false;
    if (
      this.documentation.businessLicense &&
      this.documentation.statement &&
      this.documentation.businessLicense.name &&
      this.documentation.statement.name
    ) {
      if (
        !ValidMimeTypes.types.includes(this.documentation.businessLicense.type)
      ) {
        this.documentation.showError = true;
        this.documentation.errorMessage =
          "The business license is not a valid file type";
      } else if (
        !ValidMimeTypes.types.includes(this.documentation.statement.type)
      ) {
        this.documentation.showError = true;
        this.documentation.errorMessage =
          "The statement is not a valid file type";
      } else {
        this.documentation.valid = true;
        this.currentStep++;
      }
    } else {
      this.documentation.showError = true;
      this.documentation.errorMessage = "Both files must be selected.";
    }
  }

  private validateDPPAForm(form: any) {
    this.dppaForm.showError = false;
    this.dppaForm.valid = false;
    if (this.dppaForm.accepted && this.newAccount.digitalSignature.length > 0) {
      this.dppaForm.valid = true;
      this.currentStep++;
    } else {
      this.dppaForm.errorMessage =
        "DPPA Form must be accepted and digitally signed.";
      this.dppaForm.showError = true;
    }
  }

  get userInfoForm(): Vue & { validate: () => boolean } {
    return this.$refs.userInfoForm as Vue & { validate: () => boolean };
  }

  get addressForm(): Vue & { validate: () => boolean } {
    return this.$refs.addressForm as Vue & { validate: () => boolean };
  }

  get bankInfoForm(): Vue & { validate: () => boolean } {
    return this.$refs.bankInfoForm as Vue & { validate: () => boolean };
  }

  private async submitUser(account: NewAccount): Promise<void> {
    this.termsAndConditions.showError = false;
    if (this.termsAndConditions.accepted) {
      this.termsAndConditions.creationLoading = true;
      this.newAccount.primaryUser.fullname =
        this.userInfo.firstName + " " + this.userInfo.lastName;
      this.newAccount.vtrsCustomer.customerName = this.newAccount.vtrsCustomer.businessCustomer.businessName;
      this.newAccount.vtrsCustomer.customerEmail = this.newAccount.primaryUser.email;
      this.newAccount.vtrsCustomer.physicalAddress.streetNo = "";
      this.newAccount.vtrsCustomer.physicalAddress.address1 = "";
      const firstSpaceAddress = this.address.address1.indexOf(" ");
      this.newAccount.vtrsCustomer.physicalAddress.streetNo = this.address.address1.slice(
        0,
        firstSpaceAddress
      );
      this.newAccount.vtrsCustomer.physicalAddress.address1 =
        this.address.address2.length > 0
          ? this.address.address1.slice(firstSpaceAddress + 1) +
            " " +
            this.address.address2
          : this.address.address1.slice(firstSpaceAddress + 1);
      this.newAccount.accountNo = this.bankInfo.accountNo;
      this.newAccount.mvdsCustomerType.mvdsCustomerTypeID = this.userInfo.businessType;

      let mvdsCustomerID = 0;
      try {
        mvdsCustomerID = (await RH.createAccount(account)).mvdsCustomerID;
      } catch (e) {
        this.termsAndConditions.errorMessage =
          "Sign up failed due to a server error.";
        this.termsAndConditions.showError = true;
        this.termsAndConditions.creationLoading = false;
        return;
      }
      try {
        await RH.uploadDocument(
          mvdsCustomerID,
          this.documentation.businessLicense,
          "businessLicense",
          false
        );
        await RH.uploadDocument(
          mvdsCustomerID,
          this.documentation.statement,
          "statement",
          false
        );

        this.$root.$emit("snackbar-message", "Account Created Successfully");
      } catch (e) {
        try {
          await RH.updateAccountStatusNonAdmin(
            mvdsCustomerID.toString(),
            "E",
            "Your documentation failed to upload on signup."
          );
          this.$root.$emit(
            "snackbar-message",
            "Signup was successful, but your documentation has failed to upload. Your account has been placed into edit mode. Please login and reupload your documentation."
          );
        } catch (e) {
          this.$root.$emit(
            "snackbar-message",
            "Signup was successful, but your documentation has failed to upload."
          );
        }
      } finally {
        this.termsAndConditions.creationLoading = false;
        this.$router.push({ name: "Login" });
      }
    } else {
      this.termsAndConditions.errorMessage =
        "Terms and Conditions must be accepted.";
      this.termsAndConditions.showError = true;
    }
  }

  private clearBankFields() {
    this.newAccount.routingNo = "";
    this.bankInfo.bankName = "";
    this.bankInfo.accountNo = "";
    this.bankInfo.confirmAccountNo = "";
    this.newAccount.accountName = "";
    this.bankInfo.routingNoErrorMsg = "";
    this.bankInfo.accountNoErrorMsg = "";
    this.bankInfo.accountNameErrorMsg = "";
    this.bankInfo.confirmAccountNoErrorMsg = "";
  }

  private onScrollTerms() {
    const terms = document.getElementById("termsAndConditionsBox");
    this.termsAndConditions.scrolled =
      terms!.scrollTop + terms!.clientHeight >= terms!.scrollHeight;
  }

  async beforeCreate() {
    this.config = await RH.getSmartyStreetsConfig();
    this.configLoaded = true;
  }

  async created() {
    this.userInfo.stateBoxItems = await RH.getStates();
    this.termsAndConditions.text = await RH.getTermsAndConditions();
    this.userInfo.businessTypeBoxItems = await RH.getCustomerTypes();
  }
}
